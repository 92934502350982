@font-face {
  font-family: 'KG Second Chances Sketch';
  src: url('fonts/KGSecondChancesSketch.woff2') format('woff2'),
      url('fonts/KGSecondChancesSketch.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.sketch-font {
  font-family: 'KG Second Chances Sketch', "Comic Sans MS", "Comic Sans", cursive;
}